<template>
	<div class="modal fade" id="changeFunnelModal" tabindex="-1" aria-labelledby="Смена этапа воронки" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 275px;">Выберите новую воронку</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<InputSelect inputId="funnel_id"
								 inputLabel="Список воронок:"
								 inputName="funnel_id"
								 ref="funnel_id"
								 :inputOptions="this.funnels_list"
								 :inputValue="this.funnel_id"
								 inputClass="rounded-1"
								 inputWrapClass="flex-column mb-3"
								 :inputRequired="true"
								 inputUsePlaceholder="true"
								 @setValue="(value) => { this.funnel_id = value }" />
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						:class="{'disabled' : !(this.funnel_id || this.funnels_list)}"
						data-bs-dismiss="modal"
						:disabled="!this.is_valid && !this.sources_count"
						@click="this.initChangeFunnel()">
						Подтвердить
					</button>
				</div>
			</div>
		</div>
	</div>
	<ResultsModal id="changeFunnel"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"	/>
</template>

<script>

import CommonService from "@/services/CommonService";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import InputSelect from "@/components/inputs/InputSelect";

export default {
	name: 'ChangeFunnelModal',
	props: {
		section: { type: String },
		sourcesIds: { type: Array },
		funnelsList: { type: Array }
	},
	data() {
		return {
			funnel_id: null,
			funnels_list: (typeof this.funnelsList !== "undefined") ? this.funnelsList : [],
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
		}
	},
	components: {
		InputSelect,
		ResultsModal
	},
	methods: {
		resetData() {
			this.funnel_id = null;
			this.funnels_list = [];
			this.resultsModalState = {
				state: '',
				title: '',
				message: ''
			};
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		getFunnels(section) {
			if (this.$store.clients_funnels)
				return this.$store.clients_funnels;
			else
				return api.get('common/funnels', {
					params: {
						section: section,
						use_counters: true,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					} else {
						return {};
					}
				}).catch(function (error) {

					CommonService.log('error', 'getFunnels()::axios', error);

				});
		},
		initChangeFunnel() {
			if (!this.isEmpty(this.sources_ids) && this.sources_ids) {
				api.put('/common/funnels', {
					section: this.$props.section,
					funnel_id: this.funnel_id,
					sources_ids: this.sources_ids
				}).then((response) => {
					CommonService.log('debug', 'initChangeFunnel()::axios', {response});

					if (response.status == 200 && response.data.success) {
						this.resultsModalState.state = 'success';
						this.resultsModalState.title = 'OK';

						if (this.sources_count > 1) {
							if (this.$props.section == 'clients')
								this.resultsModalState.message = 'Воронка для клиентов была успешно назначена!';
							else if (this.$props.section == 'requisitions')
								this.resultsModalState.message = 'Воронка для заявок была успешно назначена!';
							else if (this.$props.section == 'objects')
								this.resultsModalState.message = 'Воронка для объектов была успешно назначена!';
						} else {
							if (this.$props.section == 'clients')
								this.resultsModalState.message = 'Воронка для клиента была успешно назначена!';
							else if (this.$props.section == 'requisitions')
								this.resultsModalState.message = 'Воронка для заявки была успешно назначена!';
							else if (this.$props.section == 'objects')
								this.resultsModalState.message = 'Воронка для объекта была успешно назначена!';
						}

						this.$emit('initChangeFunnel', {clients_ids: this.clients_ids});
						this.resetData();
					} else {
						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Произошла ошибка!';

						if (this.sources_count > 1) {
							if (this.$props.section == 'clients')
								this.resultsModalState.message = 'Воронка для клиентов не была назначена.';
							else if (this.$props.section == 'requisitions')
								this.resultsModalState.message = 'Воронка для заявок не была назначена.';
							else if (this.$props.section == 'objects')
								this.resultsModalState.message = 'Воронка для объектов не была назначена.';
						} else {
							if (this.$props.section == 'clients')
								this.resultsModalState.message = 'Воронка для клиента не была назначена.';
							else if (this.$props.section == 'requisitions')
								this.resultsModalState.message = 'Воронка для заявки не была назначена.';
							else if (this.$props.section == 'objects')
								this.resultsModalState.message = 'Воронка для объекта не была назначена.';
						}
					}
				}).catch(function (error) {

					CommonService.log('error', 'initChangeFunnel()::axios', error);

					this.resultsModalState.state = 'error';
					this.resultsModalState.title = 'Произошла ошибка!';
					this.resultsModalState.message = 'Воронка не была назначена.';

				});
			} else {
				this.resultsModalState.state = 'error';
				this.resultsModalState.title = 'Ошибка!';

				if (this.$props.section == 'clients')
					this.resultsModalState.message = 'Необходимо выбрать хотя бы одну воронку/клиента для назначения.';
				else if (this.$props.section == 'requisitions')
					this.resultsModalState.message = 'Необходимо выбрать хотя бы одну воронку/заявку для назначения.';
				else if (this.$props.section == 'objects')
					this.resultsModalState.message = 'Необходимо выбрать хотя бы одну воронку/объект для назначения.';
			}
		},
	},
	mounted() {
		this.getFunnels(this.$props.section).then(data => { this.funnels_list = data });
	},
	computed: {
		sources_ids() {
			return this.$props.sourcesIds ?? []
		},
		sources_count() {
			return this.sources_ids.length
		},
	}
}
</script>