<template>
	<div :class="wrapper">
		<label :for="id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>

		<ckeditor :id="id"
				  class="d-none form-control" :class="this.class"
				  :name="name"
				  :editor="this.editor"
				  v-model="this.editorData"
				  :config="this.config"
				  ref="textarea"
				  :placeholder="placeholder"
				  :rows="rows"
				  :disabled="disabled"
				  :readonly="readonly"
				  @mounted="onMounted($event)"
				  @input="this.setValue" />
		<div v-if="valid && typeof valid !== 'boolean'" class="valid-feedback" v-text="valid" />
		<div v-else-if="invalid && typeof invalid !== 'boolean'" class="invalid-feedback" v-text="invalid" />
		<div v-if="help" class="form-text" :class="helpClass" v-html="help" />
	</div>
</template>


<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CommonService from "@/services/CommonService";

export default {
	name: 'Editor',
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		editorConfig: {type: Object},
		inputValue: {type: String},
		inputClass: {type: String},
		inputWrapClass: {type: String},
		inputRows: {type: [Number, String]},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputPlaceholder: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			rows: (typeof this.inputRows !== "undefined") ? this.inputRows : 3,
			help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			placeholder: (typeof this.inputPlaceholder !== "undefined") ? this.inputPlaceholder : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			editor: ClassicEditor,
			editorData: (typeof this.inputValue !== "undefined") ? this.inputValue : false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	methods: {
		onMounted(editor) {
			//this.editorInstance = editor;
		},
		setValue() {
			//this.content = this.editorInstance.getData();
			this.$emit('setValue', this.editorData);
		},
		validateInput() {
			/*let textarea = this.$refs.textarea;
			if (this.invalid)
				textarea.setCustomValidity(this.invalid);
			else
				textarea.setCustomValidity("");*/
		}
	},
	mounted() {
		this.validateInput();
	},
	watch: {
		'editorData': function(val, oldVal) {
			this.validateInput(val);
		}
	},
	computed: {
		/*editorData: {
			get() {
				return (typeof this.$props.inputValue !== "undefined") ? this.$props.inputValue : '';
			},
			set(value) {
				value = value.trim();
			}
		},*/
		config() {

			if (!CommonService.isEmpty(this.$props.editorConfig))
				return this.$props.editorConfig;

			return [];
		},
		disabled() {
			return this.$props.inputDisabled
		},
		required() {
			return this.$props.inputRequired
		},
		readonly() {
			return this.$props.inputReadonly
		},
	},
}
</script>

<style lang="scss">
	:root {
		--ck-border-radius: 6px
	}
	.ck-editor__editable {
		min-height: 260px;
	}
	.ck-editor__editable_inline {
		mix-height: 160px;
		max-height: 35vh;
	}
</style>