<template>
	<div class="modal fade" id="submitComplaintModal" tabindex="-1" aria-labelledby="Отправить жалобу на объект" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Жалоба</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<ToggleButtons
						inputId="complaint_reason"
						inputLabel="Причина:"
						inputName="complaint_reason"
						inputType="radio"
						:inputValue="this.reason"
						:inputOptions="[{
								value: 1,
								name: 'Неактуально',
							}, {
								value: 2,
								name: 'Агент',
							},]"
						inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
						inputWrapClass="flex-column mb-3" @setValue="(value) => { this.reason = value }" />

					<TextArea
						inputId="complaint_text"
						inputLabel="Описание:"
						inputName="complaint_text"
						:inputValue="this.complaint"
						inputClass="rounded-1 mb-10"
						inputWrapClass="flex-column mb-3" @setValue="(value) => { this.complaint = value }" />

				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
						@click="submitComplaint"
					>
						Отправить
					</button>
				</div>
			</div>
		</div>
	</div>
	<ResultsModal
		id="complaint"
		:state="this.resultsModalState.state"
		:title="this.resultsModalState.title"
		:message="this.resultsModalState.message"
	/>
</template>

<script>
import TextArea from "@/components/inputs/TextArea";
import ToggleButtons from "@/components/inputs/ToggleButtons";
import ResultsModal from "@/components/modals/ResultsModal";
import api from "@/api";
import CommonService from "@/services/CommonService";
export default {
	name: "SubmitComplaintModal",
	components: {
		ResultsModal,
		ToggleButtons,
		TextArea
	},
	data() {
		return {
			object_id: null,
			reason: 0,
			complaint: '',
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
		}
	},
	methods: {
		submitComplaint() {

			if (process.env.NODE_ENV == "development")
				console.debug('submitComplaint()', {
					object_id: this.object_id,
					reason: this.reason,
					complaint: this.complaint
				});

			if (!CommonService.isEmpty(this.object_id) && !CommonService.isEmpty(this.complaint)) {
				api.post('/objects/complaint', {
					object_id: this.object_id,
					reason: this.reason,
					complaint: this.complaint
				}).then((response) => {
					CommonService.log('debug', 'submitComplaint()::axios', {response});

					if (response.status == 200 && response.data.success) {
						this.object_id = null;
						this.reason = 0;
						this.complaint = '';
						this.resultsModalState.state = 'success';
						this.resultsModalState.title = 'Спасибо!';
						this.resultsModalState.message = 'Ваше обращение принято, в ближайшее время мы проверим это объявление.';
					} else {
						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Произошла ошибка!';
						this.resultsModalState.message = 'Жалоба на объект не была отправлена.';
					}
				}).catch(function (error) {

					CommonService.log('error', 'submitComplaint()::axios', error);

				});
			} else {
				this.resultsModalState.state = 'error';
				this.resultsModalState.title = 'Ошибка!';
				this.resultsModalState.message = 'Необходимо выбрать минимум один объект и указать текст жалобы.';
			}
		}
	},
	mounted() {
		let modal = document.getElementById('submitComplaintModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.reason = 0;
				_this.complaint = '';
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				_this.reason = 0;
				_this.complaint = '';

				let target = event.relatedTarget
				if (typeof target !== "undefined")
					_this.object_id = target.getAttribute('data-bs-object-id');

				if (process.env.NODE_ENV == "development") {
					console.debug('submitComplaintModal::show.bs.modal', {
						object_id: _this.object_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
}
</script>