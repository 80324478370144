<template>
    <div class="">
        <div class="input-group d-flex flex-column flex-sm-row align-items start align-items-sm-center">
            <div class="d-flex align-items-center mb-10 mb-sm-0">
                <span class="input-group-text bg-transparent border-0 me-auto me-sm-0">с</span>
                <DatePicker v-model="this.dateTimeFrom" :locale="this.dateLocale" :inputFormat="this.dateFormat" />
            </div>
            <div class="d-flex align-items-center">
                <span class="input-group-text bg-transparent border-0">по</span>
                <DatePicker v-model="this.dateTimeTo" :locale="this.dateLocale" :inputFormat="this.dateFormat" />
            </div>
        </div>
    </div>
</template>

<script>
    import DatePicker from 'vue3-datepicker'
    import { ru } from 'date-fns/locale';

    export default {
		name: "DatePickerInputBetween",
        components: {
            DatePicker
        },
		props: {
			inputDateTimeFrom: {type: String},
			inputDateTimeTo: {type: String},
			inputFormat: {type: String},
		},
        data() {
            return {
                dateLocale: ru,
            }
        },
		watch: {
			dateTimeFrom(value) {
				this.$emit('setValue', { from: value, to: this.dateTimeTo });
			},
			dateTimeTo(value) {
				this.$emit('setValue', { from: this.dateTimeFrom, to: value });
			},
		},
		computed: {
			dateTimeFrom() {
				if (typeof this.$props.inputDateTimeFrom !== "undefined")
					return new Date(this.$props.inputDateTimeFrom);
				else
					return "";
			},
			dateTimeTo() {
				if (typeof this.$props.inputDateTimeTo !== "undefined")
					return new Date(this.$props.inputDateTimeTo);
				else
					return "";
			},
			dateFormat() {
				if (typeof this.$props.inputFormat !== "undefined")
					return this.$props.inputFormat;
				else
					return 'dd.MM.yyyy';
			},
		},
    }
</script>

<style lang="scss">

    .v3dp__input_wrapper input {
        border: 1px solid #DEDEDE;
        border-radius: 6px;
        min-height: 40px;
        padding: 10px;
        width: 120px !important;
    }

    .v3dp__popout {
        top: calc(100% + 10px) !important;
        left: 50% !important;
        transform: translateX(calc(-50% - 30px));
        padding: 10px !important;
        border-radius: 12px !important;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

        @media(max-width: 479px) {
            transform: translateX(calc(-50% + 30px));
        }
    }

    .v3dp__heading {
        margin-bottom: 10px;
        &__button {
            width: 44px !important;
            height: 44px !important;
            background: #F5F5F5  !important;
            border: 1px solid #DEDEDE !important;
            border-radius: 12px !important;
        }
    }

    .v3dp__subheading {
        margin-top: 0 !important;

        span {
            display: block;
            padding-top: 1em;
            padding-bottom: 1em;
            background-color: #F2F2F2;
        }
    }

    .v3dp__divider {
        display: none !important;
    }

    .v3dp__elements {
        button {
            border: 1px solid #DEDEDE !important;
            padding: 0 !important;
            
            span {
                display: flex !important;
                align-items: center;
                justify-content: center;
                padding: 0.3em .6em;
                color: #222222 !important;
            }
        }
    }

    .v3dp__elements button.selected span {
        background-color: transparent !important;
        border: 1px solid #4Caf50;
        color: #222222 !important;
    }

    .v3dp__elements button:not(:disabled):hover span {
        background: #FFFFFF !important;
        border: 1px solid #939393 !important;
        color: #222222 !important;
    }

    .v3dp__elements button:disabled {
        opacity: 0.5 !important;
    }
</style>