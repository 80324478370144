<template>
	<div :class="wrapper">
		<label :for="id" class="form-label" :class="this.labelClass">
			{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
		</label>
		<div class="d-inline-flex align-items-stretch input-group toggle-buttons">
			<div v-if="prepend" v-html="prepend" class="input-group-text text-dark-gray border-0 bg-transparent p-0 pe-3" />
			<div class="btn-group flex-wrap"
				 :class="(valid) ? 'is-valid' : ((invalid) ? 'is-invalid' : '')"
				 role="group"
				 :aria-label="label">
				<div v-for="(option, index) in options" :key="option.value">
					<input :id="'btnCheck' + id + '_' + index"
						   :type="(type == 'checkbox') ? 'checkbox' : 'radio'"
						   class="btn-check"
						   :name="name"
						   ref="button"
						   :value="option.value"
						   :disabled="disabled"
						   :required="required"
						   :readonly="readonly"
						   autocomplete="off"
						   :checked="option.checked || option.value === value"
						   @change="handleChange(option)" />
					<label class="btn"
						   :class="this.class"
						   :for="'btnCheck' + id + '_' + index"
						   v-text="option.name" />
				</div>
			</div>
			<div v-if="append" v-html="append" class="input-group-text text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />
			<div v-if="valid && typeof valid !== 'boolean'" class="valid-feedback" v-text="valid" />
			<div v-else-if="invalid && typeof invalid !== 'boolean'" class="invalid-feedback" v-text="invalid" />
		</div>
		<div v-if="help" class="form-text" :class="helpClass" v-html="help" />
	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	name: 'ToggleButtons',
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValue: {type: [Number, String]},
		inputType: {type: String},
		inputOptions: {type: [Array, Object]},
		inputBtnClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputPrepend: {type: String},
		inputAppend: {type: String},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : '',
			//options: (typeof this.inputOptions !== "undefined") ? this.inputOptions : [],
			class: (typeof this.inputBtnClass !== "undefined") ? this.inputBtnClass : 'btn-light-gray',
			wrapper: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			prepend: (typeof this.inputPrepend !== "undefined") ? this.inputPrepend : false,
			append: (typeof this.inputAppend !== "undefined") ? this.inputAppend : false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
		};
	},
	watch: {
		'value': function(val, oldVal) {
			//this.validateInput(val);
		}
	},
	methods: {
		handleChange(option) {
			if (this.type !== 'checkbox') {
				this.$emit('setValue', option.value)
			} else {
				option.checked = !option.checked
				this.$emit('applyFilters')
			}
		}
		/*validateInput() {
			let button = this.$refs.button;
			if (this.invalid)
				button.setCustomValidity(this.invalid);
			else
				button.setCustomValidity("");
		}*/
	},
	mounted() {
		//this.validateInput();
	},
	computed: {
		invalid() {
			return (typeof this.inputInValid !== "undefined") ? this.inputInValid : false
		},
		value() {
			return this.$props.inputValue
		},
		options() {
			if (this.type === 'checkbox' && Array.isArray(this.value)) {
				this.$props.inputOptions.forEach(option => {
					if (this.value.includes(option.value)) {
						option.checked = true
					}
				})
			}

			if (!CommonService.isEmpty(this.$props.inputOptions))
				return this.$props.inputOptions;

			return [];
		},
		disabled() {
			return this.$props.inputDisabled
		},
		required() {
			return this.$props.inputRequired
		},
		readonly() {
			return this.$props.inputReadonly
		},
		type() {
			return typeof this.inputType !== "undefined" ? this.inputType : 'radio'
		}
	}
}
</script>