<template>
    <ul class="row-fluid p-0">
        <li
            v-for="(object, i) of objectList"
            :key="'object_' + i"
            :class="this.$props.cardType === 'type_4' ? 'bg-white rounded-3 border mb-20 border-brd-primary' : null"
        >
            <ObjectCard 
                :objectItem="object"
                :cardType="this.$props.cardType"
                :identifier="this.$props.identifier"
            />
        </li>
    </ul>
</template>

<script>
    import ObjectCard from "@/components/common/ObjectCard"

    export default {
        props: {
            cardType: { type: String },
            identifier:  { type: String }
        },

        components: {
            ObjectCard,
        },

        data() {
            return {
                objectList: [
                    {
                        id: 16505422313,
                        type: 0,
                        category: 1,
                        title: 'Квартира-студия, 24,4 м, 11/16 эт.',
                        address: 'Санкт-Петербург, Мебельная ул. 19к2',
                        published_at: '23 сентября 2021 в 16:25',
                        isWatched: true,
                        agency: {
                            name: 'Агентство Недвижимости очень длиннннннннное нозвание',

                            phones: [
                                '89586002873',
                            ]
                        },
                        photos: [
                            {
                                fullsize: 'data/images/object_01.png',
                                thumbnail: 'data/images/object_01-thumb.png',
                            }, {
                                fullsize: 'data/images/object_01.png',
                                thumbnail: 'data/images/object_01-thumb.png',
                            }, {
                                fullsize: 'data/images/object_01.png',
                                thumbnail: 'data/images/object_01-thumb.png',
                            }
                        ],
                        areas: {
                            full: 2433,
                            live: 1433,
                            kitchen: 0,
                        },
                        floors: {
                            num: 11,
                            max: 16
                        },
                        rayon: 'Приморский район',
                        metro: {
                            id: 1,
                            station: 'Беговая',
                        },
                        do_metro: {
                            time: 15,
                            type: 1
                        },
                        author: {
                            id: 111,
                            fio: "Нектов Некто Нектович",
                            is_owner: true,
                        },
                        is_favoured: true,
                        use_in_advert: true,
                        advertised: {
                            free: true,
                            avito: true,
                            yandex: false,
                            cian: true,
                            domclick: true,
                            zipal: false,
                        },
                        tags: [
                            {
                                id: 1,
                                label: 'Дикси'
                            }, {
                                id: 2,
                                label: 'Магнит'
                            }, {
                                id: 3,
                                label: 'Новостройки'
                            }
                        ],
                        price: {
                            amount: 100900000,
                            currency: "RUR",
                            commission: 0.54,
                        },
                        source: {
                            id: 1,
                            name: 'Авито',
                            url: 'https://avito.ru/#',
                        },
                        geo: {
                            lat: 50.00001,
                            lng: 50.00001,
                        },
                    },

                    {
                        id: 16505413,
                        type: 1,
                        category: 1,
                        title: 'Квартира-студия, 24,4 м, 11/16 эт.',
                        address: 'Санкт-Петербург, Мебельная ул. 19к2',
                        published_at: '23 сентября 2021 в 16:25',
                        isWatched: false,
                        agency: {
                            name: 'Агентство Недвижимости очень длиннннннннное нозвание',

                            phones: [
                                '89586002873',
                                '89586002874',
                            ]
                        },
                        photos: [
                            {
                                fullsize: 'data/images/object_01.png',
                                thumbnail: 'data/images/object_01-thumb.png',
                            }, {
                                fullsize: 'data/images/object_01.png',
                                thumbnail: 'data/images/object_01-thumb.png',
                            }, {
                                fullsize: 'data/images/object_01.png',
                                thumbnail: 'data/images/object_01-thumb.png',
                            }
                        ],
                        areas: {
                            full: 2433,
                            live: 1433,
                            kitchen: 0,
                        },
                        floors: {
                            num: 11,
                            max: 16
                        },
                        rayon: 'Приморский район',
                        metro: {
                            id: 1,
                            station: 'Беговая',
                        },
                        do_metro: {
                            time: 15,
                            type: 1
                        },
                        author: {
                            id: 111,
                            fio: "Нектов Некто Нектович",
                            is_owner: true,
                        },
                        is_favoured: false,
                        use_in_advert: true,
                        advertised: {
                            free: true,
                            avito: true,
                            yandex: false,
                            cian: true,
                            domclick: true,
                            zipal: false,
                        },
                        tags: [
                            {
                                id: 1,
                                label: 'Дикси'
                            }, {
                                id: 2,
                                label: 'Магнит'
                            }, {
                                id: 3,
                                label: 'Новостройки'
                            }
                        ],
                        price: {
                            amount: 100900000,
                            currency: "RUR",
                            commission: 0.54,
                        },
                        source: {
                            id: 1,
                            name: 'Авито',
                            url: 'https://avito.ru/#',
                        },
                        geo: {
                            lat: 50.00001,
                            lng: 50.00001,
                        },
                    },
                ],
            }
        }
    }
</script>