<template>
	<div>
		<div class="modal fade" id="editObjectDescrModal" ref="editObjectDescrModal" tabindex="-1" aria-labelledby="Редактирование описания" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-0">
						<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							Редактирование цены/описания
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column p-0">
						<InputText inputId="price"
								   inputLabel="Цена:"
								   inputName="price"
								   ref="price"
								   :inputValue="this.price ?? null"
								   inputClass="rounded-1 p-2"
								   inputWrapClass="flex-column mb-2"
								   @setValue="value => this.price = value" />
						<div v-if="this.use_editor">
							<Editor :inputValue="this.description ?? null"
									@setValue="value => this.description = value" />
						</div>
						<div v-else>
							<TextArea :inputValue="this.description ?? null"
									  inputRows="6"
									  @setValue="value => this.description = value" />
						</div>
					</div>
					<div class="modal-footer border-top-0 p-0 pt-3">
						<button type="button"
								class="btn btn-success col-12 rounded-3 py-3 text-white fs-3"
								data-bs-dismiss="modal"
								@click="this.saveDescription()">
							Сохранить
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import CommonService from "@/services/CommonService";
import Editor from "@/components/inputs/Editor";
import TextArea from "@/components/inputs/TextArea";
import InputText from "@/components/inputs/InputText";

export default {
	name: 'EditObjectDescrModal',
	components: {InputText, TextArea, Editor },
	props: {
		objectId: { type: Number },
		useEditor: { type: Boolean },
		needSave: { type: Boolean }
	},
	data() {
		return {
			object_id: null,
			description: null,
			price: null,
			need_save: (this.$props.needSave == true || this.$props.needSave === 'true') ? true : false,
			errors: [],
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		getObjectDescription(object_id) {
			return api.get('/object/description', {
				params: {
					section: 'pdf',
					object_id: object_id,
					html: false
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjectDescription()::axios', {response: response.data});

				if (response.status == 200 && response.data.success)
					return response.data;

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getObjectDescription()::axios', error);

			});
		},
		saveDescription() {
			this.$emit('saveDescription', {
				object_id: this.object_id,
				description: this.description,
				price: this.price
			});
		},
		showModal() {
			let modalEl = this.$refs.editObjectDescrModal;
			let modal = new bootstrap.Modal(modalEl);

			if (this.isEmpty(this.description)) {
				this.getObjectDescription(this.object_id).then((data) => {
					this.$nextTick((() => {
						this.price = data.price;
						this.description = data.description;
					}));
					modal.show();
				});
			} else {
				modal.show();
			}
		},
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.editObjectDescrModal;
		if (modal && typeof modal !== "undefined") {
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.errors = [];
				_this.object_id = null;
				_this.description = null;
				_this.price = null;
				_this.emitter.emit("global.modalClose", modal);
			});
			modal.addEventListener('show.bs.modal', function (event) {

				let target = event.relatedTarget
				if (target) {
					let object_id = target.getAttribute('data-bs-object-id');
					if (typeof object_id !== "undefined") {
						_this.object_id = object_id;
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('editObjectDescrModal::show.bs.modal', {
						object_id: _this.object_id,
						description: _this.description,
						price: _this.price,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {

	},
}
</script>