<template>
	<nav v-if="this.objectsCount > 0" class="navbar presentation-nav fixed-bottom navbar-light px-10" style="z-index: 990 !important">
		<div class="container-fluid">
			<div class="col">
				<span class="d-inline-flex badge bg-danger p-2 rounded-pill align-items-center ms-0 me-auto">
					<img class="d-inline-flex" :src="require('@/assets/icons/target.svg')" alt="Выбрано объектов" width="24" height="24">
					<span class="d-inline-flex px-2 fs-3" v-text="this.objectsCount" />
				</span>
			</div>
			<div class="col d-flex align-content-center">
				<button 
					class="btn bg-white shadow d-inline-flex align-items-center p-3 rounded-circle mx-auto"
					data-bs-toggle="modal"
					:data-bs-target="'#sendPresentationsModal'">
					<img class="d-inline-flex" :src="require('@/assets/icons/arrow-top.svg')" alt="Развернуть" width="12" height="12">
				</button>
			</div>
			<div class="col" />
		</div>
	</nav>
	<SendPresentationsModal :objectsList="objectsItems" />
</template>

<script>
    import SendPresentationsModal from "@/components/modals/SendPresentationsModal";

	export default {
        name: "PresentationNav",
		components: {
			SendPresentationsModal
		},
		data() {
            return {
				objects: this.$store.state.to_pdf,
				count: this.$store.state.to_pdf.length,
            }
        },
        methods: {

        },
        computed: {
			objectsItems() {
				return this.$store.state.to_pdf;
			},
			objectsCount() {
				return this.$store.state.to_pdf.length;
			}
        }
    };
</script>
<style scoped="scoped" lang="scss">

.presentation-nav {
	background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
}

</style>