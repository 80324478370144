<!--
    /*Типы контента карточек и их привязки к модулям и секциям проекта*/

    /*Для работы нужно передать тип в зависимости от которого показываются нужные поля(type_*)*/ 
    /*И уникальное название для id(identifier), чтобы слайдеры работали корректно*/

    --------------
    /*Ниже записываем использование компонента в модулях и их блоках*/
    --------------

    type_1: {
        modules: 'requisition',
        sections: 'requestEdit'
    },

    type_2: {
        modules: 'requisition',
        sections: 'requestSearchIdentical, requestSearchSame'
    }

    type_3: {
        modules: 'search',
        sections: ''
    },

    type_4: {
        modules: 'objects',
        sections: ''
    }
-->
 
<template>
    <div :id="'object' + item.id" class="object-card" :class="this.$props.cardType !== 'type_4' ? mb-20 : null">
        <div 
            :id="this.$props.identifier + item.id" 
            class="crm-carousel carousel slide mb-20" 
            data-bs-touch="true" 
            data-bs-keyboard="true" 
            data-bs-ride="false"
        >
            <div class="carousel-inner rounded-3">
                <div class="position-relative">
                    <div v-for="(photo, index) of item.photos"
                        :key="index"
                        :class="['crm-carousel-item carousel-item', (index == 0) ? 'active' : '']"
                        :style="`background-image: url('/assets/` + photo.thumbnail + `');`"
                        :title="'Фото ' + (index+1)"
                        aria-label="Изображение объекта"
                    />

                    <span class="badge crm-badge-sticker">{{item.author.is_owner ? 'Собственник' : 'Агент JW'}}</span>

                    <button 
                        type="button" 
                        class="crm-carousel-favor-btn crm-btn btn btn-light btn-lg rounded-circle"
                        v-if="this.$props.cardType !== 'type_1'"
                    >
                        <img v-if="item.is_favoured" :src="require('@/assets/icons/heart-fill.svg')" alt="Убрать из избранного" width="24" height="24" class="d-block">
                        <img v-else :src="require('@/assets/icons/heart.svg')" alt="В избранное" width="24" height="24" class="d-block">
                    </button>
                </div>
            </div>

            <button 
                class="crm-carousel-item-prev carousel-control-prev rounded-circle ms-10"
                type="button" 
                :data-bs-target="'#' + this.$props.identifier + item.id" 
                data-bs-slide="prev"
            >
                <img :src="require('@/assets/icons/slider-arr-w-left.svg')" alt="..." width="12" height="12">

                <span class="visually-hidden">Предыдущий слайд</span>
            </button>

            <button 
                class="crm-carousel-item-next carousel-control-next rounded-circle me-10"
                type="button" 
                :data-bs-target="'#' + this.$props.identifier + item.id" 
                data-bs-slide="next"
            >
                <img :src="require('@/assets/icons/slider-arr-w-right.svg')" alt="..." width="12" height="12">

                <span class="visually-hidden">Следующий слайд</span>
            </button>
        </div>

        <div 
            class="object-card-body"
            :class="this.$props.cardType === 'type_4' ? 'px-20' : null"
        >
            <!--шапка карточки-->
            <div class="object-card-header mb-20">
                <span 
                    class="d-inline-block border border-warning rounded-1 text-warning p-2 fs-5 mb-2"
                    v-if="this.$props.cardType === 'type_2' && item.isWatched"
                >
                    Просмотрено
                </span>
                <div class="d-flex align-items-center mb-3">
                    <div class="d-flex flex-column flex-sm-row text-nowrap-mask me-auto">
                        <span class="text-font-secondary fs-5 me-20">{{ item.published_at }}</span>

                        <span class="text-font-secondary fs-5 me-20">
                            {{item.type === 0 ? 'Сдаётся' : 'Продается'}}
                        </span>

                        <span class="text-font-secondary fs-5 me-20">ID {{ item.id }}</span>
                    </div>

                    <button 
                        type="button"
                        class="btn btn-type_2 btn-outline-light rouded-3 me-1"
                    >
                        <img :src="require('@/assets/icons/dots-horz.svg')" alt="..." width="24" height="24" class="d-block">
                    </button>
                </div>
                
                <div class="form-check me-auto d-flex align-items-center">
                    <input class="form-check-input me-10 rounded-1" type="checkbox" value="1" :id="'objectChecked' + item.id">

                    <span 
                        class="w-100 fw-semi font-semi fs-3 text-nowrap-mask"
                        @click="isFullMode = !isFullMode"
                        style="cursor: pointer;"
                    >
                        {{ item.title }}
                    </span>
                </div>
            </div>

            <!--шапка карточки конец-->

            <div v-if="isFullMode">
            <!--описание карточки-->
                <div class="mb-20 border-bottom border-brd-primary" >
                    <div 
                        class="d-flex flex-column flex-sm-row"
                    >

                        <div class="w-100 w-sm-50 pe-20">
                            <ul class="m-0 p-0">
                                <li class="d-flex align-items-center mb-3">
                                    <img :src="require('@/assets/icons/metro-icon.svg')" alt="Метро" width="20" height="20" class="me-10">
                                    <span class="d-block fs-4">{{ item.metro.station }}</span>
                                </li>
            
                                <li class="mb-20">
                                    <span class="d-block text-font-secondary fs-4">{{ item.rayon }}</span>
                                </li>
            
                                <li class="mb-20">
                                    <span class="d-block text-font-secondary fs-4">{{ item.do_metro.time }} минут пешком</span>
                                </li>
            
                                <li class="mb-20">
                                    <span class="d-block fs-4">{{ item.address }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="w-100 w-sm-50">
                            <ul class="m-0 p-0">
                                <li class="mb-3">
                                    <span class="d-block fs-4">{{ item.areas.type_4 }} кв.м.</span>
                                </li>
                                
                                <li class="mb-20">
                                    <span class="d-block text-font-secondary fs-4">Жилая {{ item.areas.live }} кв.м.</span>
                                </li>

                                <li class="mb-20">
                                    <span class="d-block fs-4">{{ item.floors.num }} этаж из {{ item.floors.max }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span v-if="item.source && this.$props.cardType !== 'type_1'" class="d-block text-font-secondary mb-20">
                        Ссылка на источник: 
                        <a :href="item.source.url" target="_blank" class="crm-underline text-font-primary">{{ item.source.name }}</a>
                    </span>
                </div>
                <!--описание карточки конец-->

                <!--действия в карточке-->

                <div>
                    <div class="d-flex flex-wrap align-items-center mb-10">
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-danger rounded-3 me-2" title="Отправить в презентацию">
                            <img :src="require('@/assets/icons/pdf-file-white.svg')" alt="PDF" width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-vkontakte rounded-3 me-2" title="Опубликовать в вКонтакте">
                            <img :src="require('@/assets/icons/vk.svg')" alt="ВКонтакте" width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Проверить чек-лист" v-if="this.$props.cardType !== 'type_1'">
                            <img :src="require('@/assets/icons/checklist.svg')" alt="Чек-лист" width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Заявки по автопоиску" v-if="this.$props.cardType !== 'type_1' && this.$props.cardType !== 'type_2'">
                            <img :src="require('@/assets/icons/zoom-in.svg')" alt="Автопоиск" width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Передать сотруднику" v-if="this.$props.cardType !== 'type_1' && this.$props.cardType !== 'type_2'">
                            <img :src="require('@/assets/icons/stay-home.svg')" alt="..." width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Передать сотруднику" v-if="this.$props.cardType !== 'type_1'">
                            <img :src="require('@/assets/icons/planning.svg')" alt="..." width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Отчёт по рекламе" v-if="this.$props.cardType !== 'type_1'">
                            <img :src="require('@/assets/icons/graph.svg')" alt="Отчёт" width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Добавить в архив" v-if="this.$props.cardType === 'type_4'">
                            <img :src="require('@/assets/icons/folder.svg')" alt="Архивировать" width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Отменить передачу" v-if="item.is_transfered && this.$props.cardType === 'type_4'">
                            <img :src="require('@/assets/icons/transfer.svg')" alt="Передача..." width="24" height="24">
                        </a>
                        <a href="javascript:{}" class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Передать сотруднику" v-else-if="!item.is_transfered && this.$props.cardType === 'type_4'">
                            <img :src="require('@/assets/icons/transfer.svg')" alt="Передать" width="24" height="24">
                        </a>
                        <a class="crm-btn btn mb-10 btn-light-gray rounded-3 me-2" title="Удалить" v-if="this.$props.cardType === 'type_2'">
                            <img :src="require('@/assets/icons/basket-icon.svg')" alt="Передать" width="24" height="24">
                        </a>
                        
                        <div class="d-flex align-items-center" v-if="this.$props.cardType === 'type_4'">
                            <a href="javascript:{}" class="d-flex align-items-center justify-content-center btn btn-outline-warning rounded-3 mb-10 me-2 px-3" style="height: 50px;">
                                Выписка
                            </a>
                            <a href="javascript:{}" class="d-flex align-items-center justify-content-center btn btn-outline-success rounded-3 mb-10 me-2 px-3" style="height: 50px;">
                                Реклама
                            </a>
                        </div>
                    </div>

                    <div class="form-check form-switch d-flex align-items-center p-0 mb-20" v-if="this.$props.cardType === 'type_4'">
                        <input class="crm-switch border-brd-primary form-check-input bg-light-gray me-10" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="item.use_in_advert">
                        <label class="form-check-label text-secondary" for="flexSwitchCheckChecked">В рекламу</label>
                    </div>
                </div>

                <!--действие в карточке конец-->
                
                <!--футер карточки-->
                <div class="">
                    <div class="d-flex flex-column flex-sm-row border-bottom border-top border-brd-primary py-20">
                        <div class="d-flex flex-column w-100 w-sm-50">
                            <div class="d-flex align-items-center me-auto">
                                <img :src="require('@/assets/icons/graph-icon.svg')" alt="Иконка графика" width="26" height="26" class="me-10" v-if="this.$props.cardType === 'type_4'">

                                <span class="font-semi fw-semi fs-2 pe-20 text-nowrap-mask">{{ formatPrice(item.price.amount, item.price.currency, true) }}</span>
                            </div>
                            <div v-if="item.price.commission > 0 && this.$props.cardType === 'type_4'" class="text-dark-gray fs-5">Комиссия
                                {{ item.price.commission + '%' }} - {{
                                    formatPrice(Math.round((item.price.amount * item.price.commission) / 100, 2), item.price.currency)
                                }}
                            </div>
                        </div>

                        <div class="d-flex flex-column text-nowrap-mask w-100 w-sm-50">
                            <span class="text-font-secondary fs-4 mb-10">{{ item.agency.name }}</span>

                            <ul class="m-0 p-0">
                                <li v-for="(phone, i) of item.agency.phones" :key="'phone_' + i">
                                    <span class="fw-semi font-semi fs-3">{{ phone }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div 
                        class="py-2" v-if="this.$props.cardType !== 'type_1'"
                        :class="this.$props.cardType !== 'type_4' ? 'border-bottom border-brd-primary' : null"
                    >
                        <a href="javascript:{}" class="btn btn-light text-dark-gray">На карте</a>
                        <a href="javascript:{}" class="btn btn-light text-dark-gray">Описание</a>
                    </div>
                </div>
            </div>
            <!--футер карточки конец-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "ObjectPreview",

        props: {
            objectItem: { type: Object },
            cardType: { type: String },
            identifier: { type: String}
        },

        data() {
            return {
                isFullMode: false,
                item: (typeof this.objectItem !== "undefined") ? this.objectItem : {}
            };
        },

        methods: {
            formatPrice(value, currency, sign) {
                let post = '', prep = '';
                let val = (parseFloat(value) / 1).toFixed(2).replace('.', ',');

                if (currency == "RUR") {
                    post = (sign) ? " ₽" : " руб.";
                } else if (currency == "USD") {

                    if (sign)
                        prep = "$";
                    else
                        post = " долл. США";

                } else if (currency == "EUR") {

                    if (sign)
                        prep = "€";
                    else
                        post = " евро";
                }

                value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                value = value.replace(',00', "");
                return prep + value + post;
            }
        }
    }
</script>