<template>
    <div class="modal fade modal-fullscreen"
		 id="editRequisitionModal"
		 tabindex="-1"
		 aria-labelledby="Редактирование заявки"
		 data-bs-backdrop="static"
		 data-bs-keyboard="false"
		 aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content rounded-1">
                <div class="modal-header p-20 d-flex flex-column align-items-start mb-20 p-0 border-0 ">
                    <div class=" d-flex align-items-center container-fluid p-1 mb-20">
                        <span v-if="!this.isEmpty(this.item.id)" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Редактирование заявки</span>
                        <span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Новая заявка</span>
                        <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <!--steps-->
                    <div>
                        <ul class="step-list d-flex flex-column flex-sm-row align-items-start align-items-sm-center m-0 p-0">
                            <li class="nav-item d-flex align-items-center me-20 mb-10 mb-sm-0">
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill opacity-1"
                                    @click="this.requestEditFormStep = 1"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 lh-1
                                            "
                                        :class="requestEditFormStep === 1 ? 'border border-primary text-primary' : 'border border-font-light text-font-light'"
                                        
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        1
                                    </span>
                                    <span 
                                        class="font-semi fw-semi px-2"
                                        :class="requestEditFormStep === 1 ? 'text-primary' : 'text-font-light'"
                                        :style="requestEditFormStep !== 1 ? 'text-decoration: underline' : null"
                                    >
                                        Общие
                                    </span>
                                </button>
                            </li>

                            <li class="nav-item d-flex align-items-center me-20" >
                                <button 
                                    class="btn btn-outline d-flex align-items-center p-0 rounded-pill opacity-1"
                                    @click="this.requestEditFormStep = 2"
                                >
                                    <span
                                        class=" d-flex align-items-center justify-content-center 
                                                rounded-circle me-1 lh-1
                                            "
                                        :class="requestEditFormStep === 2 ? 'border border-primary text-primary' : 'border border-font-light text-font-light'"
                                        
                                        style="width: 40px; height: 40px; border-width: 2px;"
                                    >
                                        2
                                    </span>
                                    <span 
                                        class="font-semi fw-semi px-2"
                                        :class="requestEditFormStep === 2 ? 'text-primary' : 'text-font-light'"
                                        :style="requestEditFormStep !== 2 ? 'text-decoration: underline' : null"
                                    >
                                        {{!this.$props.isObjectExist ? 'Параметры автопоиска' : 'Объекты'}}
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!--end steps-->
                </div>

                <div class="modal-body p-20 d-flex flex-column p-1" v-if="requestEditFormStep === 1">

					<InputSelect inputId="type_id"
								 inputLabel="Тип заявки:"
								 inputName="type_id"
								 ref="type_id"
								 :inputOptions="this.types_list"
								 :inputValue="(!this.isEmpty(this.item.type_id)) ? this.item.type_id : null"
								 inputClass="rounded-1"
								 inputLabelClass="text-font-secondary mb-3"
								 inputUsePlaceholder="false"
								 inputRequired="true"
								 inputWrapClass="flex-column mb-3"
								 @setValue="(value) => this.setAndValidate('type_id', value)" />

                    <div class="d-flex align-items-center mb-20">
                        <img :src="require('@/assets/icons/options-icon.svg')" alt="Этап воронки" width="24" height="24" class="me-1">
                        <button type="button" class="btn btn-outline crm-underline text-font-secondary rounded-3">
                            Параметры поиска объектов
                        </button>
                    </div>

                    <SelectBox inputId="client_id"
							   inputLabel="Клиент:"
							   inputName="client_id"
							   ref="client_id"
							   inputClass="rounded-1 p-2"
							   inputWrapClass="flex-column mb-4"
							   :inputOptions="this.clients_list"
							   :inputValue="(!this.isEmpty(this.item.client_id)) ? this.item.client_id : null"
							   inputSearch="true"
							   inputMultiple="false"
							   inputPlaceholder="Выберите клиента"
							   inputLazyLoad="true"
							   inputRequired="true"
							   @searchInput="(term) => this.search_query = term"
							   @endOfList="(length) => this.clients_offset = length"
							   @setValue="(value) => this.setAndValidate('client_id', value)" />

                    <div class="d-flex align-items-center mb-20">
                        <img :src="require('@/assets/icons/pen-icon.svg')" alt="Этап воронки" width="22" height="22" class="me-1">
                        <button type="button" class="btn btn-outline crm-underline text-font-secondary rounded-3">
                            Изменить имя клиента
                        </button>
                    </div>

                    <div class="d-flex align-items-center  mb-20">
                        <a href="javascript:{}"
						   class="btn d-flex align-items-center p-0 mb-2 me-20 rounded-pill"
						   style="min-width: 150px;"
						   @click="this.alertNote('Функционал в стадии разработки.')">
									<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
										  style="width: 40px; height: 40px;">
										<img :src="require('@/assets/icons/add-icon.svg')"
											 class="d-block"
											 alt="Добавить"
											 width="14"
											 height="14">
									</span>
							<span class="text-font-dark fw-semi font-semi fs-4 px-2">Новый клиент</span>
						</a>
                    </div>

					<InputText
						inputId="name"
						inputLabel="Название заявки:"
						inputName="name"
						inputClass="rounded-1 mb-10"
						:inputValue="(!this.isEmpty(this.item.name)) ? this.item.name : null"
						inputWrapClass="flex-column mb-3"
						inputRequired="true"
						@setValue="(value) => this.setAndValidate('name', value)" />

					<div class="d-flex flex-column mb-10">
						<span class="text-font-secondary mb-3">Категория клиента:</span>
						<ul class="d-flex check-group flex-wrap align-items-center m-0 p-0">
							<li class="me-20 mb-10">
								<input type="radio"
									   class="btn-check"
									   name="is_hot"
									   id="is_hot"
									   autocomplete="off"
									   :checked="this.item.is_hot === 1 || Boolean(this.item.is_hot)"
									   @click="(event) => this.setAndValidate('is_hot', 1)">
								<label class="d-flex align-items-center justify-content-center btn border border-fire btn-lg rounded-circle"
									   style="width: 50px; height: 50px;"
									   for="is_hot">
									<img :src="require('@/assets/icons/fire-icon.svg')"
										 class="d-block"
										 alt="Hot"
										 width="28"
										 height="28" />
								</label>
							</li>
							<li class="me-20 mb-10">
								<input type="radio"
									   class="btn-check"
									   name="priority"
									   id="priority_1"
									   autocomplete="off"
									   :checked="this.item.priority === 1"
									   @click="(event) => this.setAndValidate('priority', 1)">
								<label class="btn btn-raspberry btn-lg rounded-circle"
									   style="width: 50px; height: 50px;"
									   for="priority_1" />
							</li>
							<li class="me-20 mb-10">
								<input type="radio"
									   class="btn-check"
									   name="priority"
									   id="priority_2"
									   autocomplete="off"
									   :checked="this.item.priority === 2"
									   @click="(event) => this.setAndValidate('priority', 2)">
								<label class="btn btn-warning btn-lg rounded-circle"
									   style="width: 50px; height: 50px;"
									   for="priority_2" />
							</li>
							<li class="me-20 mb-10">
								<input type="radio"
									   class="btn-check"
									   name="priority"
									   id="priority_3"
									   autocomplete="off"
									   :checked="this.item.priority === 3"
									   @click="(event) => this.setAndValidate('priority', 3)">
								<label class="btn btn-ocean btn-lg rounded-circle"
									   style="width: 50px; height: 50px;"
									   for="priority_3" />
							</li>
							<li class="me-20 mb-10">
								<input type="radio"
									   class="btn-check"
									   name="priority"
									   id="priority_4"
									   autocomplete="off"
									   :checked="this.item.priority === 4"
									   @click="(event) => this.setAndValidate('priority', 4)">
								<label class="btn btn-primary btn-lg rounded-circle"
									   style="width: 50px; height: 50px;"
									   for="priority_4" />
							</li>
						</ul>
					</div>

					<InputSelect inputId="funnel_id"
								 inputLabel="Воронка:"
								 inputName="funnel_id"
								 ref="funnel_id"
								 :inputOptions="this.funnels_list"
								 :inputValue="(!this.isEmpty(this.item.funnel_id)) ? this.item.funnel_id : null"
								 inputClass="rounded-1"
								 inputLabelClass="text-font-secondary mb-3"
								 inputUsePlaceholder="false"
								 inputRequired="true"
								 inputWrapClass="flex-column mb-3"
								 @setValue="(value) => this.setAndValidate('funnel_id', value)" />

<!--                    <div class="d-flex align-items-center mb-20">
                        <button 
                            type="button" 
                            class="crm-btn btn border border-brd-primary bg-light-gray rounded-3 me-10"
                        >
                            <img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="Этап воронки" width="20" height="20">
                        </button>  
                        
                        <div class="d-flex flex-column">
                            <span class="text-font-secondary fs-4">Этап воронки:</span>
                            <span>Новый</span>
                        </div>
                    </div>-->

					<SelectBox inputId="tags"
							   inputName="tags"
							   ref="tags"
							   inputLabel="Теги:"
							   :inputOptions="this.tags_list"
							   :inputValue="this.item.tags"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   inputMultiple="true"
							   inputSearch="true"
							   @setValue="(value) => this.setAndValidate('tags', value)" />

					<div class="row align-items-center flex-row mb-2">
						<div class="row-fluid mb-2">
							<div class="col col-4">
								<label for="contracts_list" class="form-label col text-dark-gray">
									Договоры:
								</label>
							</div>
							<div id="contracts_list" class="col col-8">
								<ul v-if="!this.isEmpty(this.item.contracts)"
									class="list-unstyled d-flex flex-column">
									<li v-for="contract in this.item.contracts" :key="contract.id"
										class="d-inline-block mb-2">
										<a href="javascript:{}"
										   class="link fs-4"
										   data-bs-toggle="modal"
										   data-bs-target="#addEditContractModal"
										   :data-bs-contract-id="contract.id"
										   :data-bs-source-id="item.id"
										   :data-bs-section="'clients'">
											<img :src="require('@/assets/icons/pen-icon.svg')" alt="Edit" class="d-inline-flex ms-0 me-1" width="14" height="14" style="margin-top: 1px;">
											{{ this.getContractType(contract.type) }}
											"{{ contract.number }}"
											с {{ this.formatDate('DD.MM.YYYY', contract.date_start) }}
											по {{ this.formatDate('DD.MM.YYYY', contract.date_end) }}
										</a>
									</li>
								</ul>
							</div>

							<a href="javascript:{}"
							   class="btn d-flex align-items-center p-0 mt-1 mb-2 me-20 rounded-pill"
							   style="min-width: 150px;"
							   data-bs-toggle="modal"
							   data-bs-target="#addEditContractModal"
							   :data-bs-contract-id="null"
							   :data-bs-source-id="item.id"
							   :data-bs-section="'requisitions'">
										<span class=" d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
											  style="width: 40px; height: 40px;">
											<img :src="require('@/assets/icons/add-icon.svg')"
												 class="d-block"
												 alt="Добавить"
												 width="14"
												 height="14">
										</span>
								<span class="text-font-dark fw-semi font-semi fs-4 px-2">Добавить договор</span>
							</a>
						</div>
					</div>

					<TextArea inputId="description"
							  inputName="description"
							  ref="description"
							  inputLabel="Описание:"
							  :inputValue="this.item.description"
							  inputLabelClass="text-font-secondary mb-3"
							  inputClass="rounded-3 p-2"
							  inputWrapClass="mb-20"
							  @setValue="(value) => this.setAndValidate('description', value)" />

					<div v-if="this.fields_list">
						<div v-for="(field, key) in this.fields_list" :key="field.id">

							<InputText v-if="field.type == 0"
									   :inputId="'fields_' + key"
									   :inputName="'fields_' + key"
									   :ref="'fields.' + key"
									   :input-label="field.label + ':'"
									   :input-value="this.item.fields[key]"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :input-required="field.is_required"
									   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

							<InputNumber v-else-if="field.type == 1"
										 :inputId="'fields_' + key"
										 :inputName="'fields_' + key"
										 :ref="'fields.' + key"
										 :input-label="field.label + ':'"
										 :input-value="this.item.fields[key]"
										 inputLabelClass="text-font-secondary mb-3"
										 inputClass="rounded-3 p-2"
										 inputWrapClass="mb-20"
										 :input-required="field.is_required"
										 @setValue="(value) => this.setAndValidate('fields.' + key, parseInt(value))" />

							<SelectBox v-else-if="field.type == 2"
									   :inputId="'fields_' + key"
									   :inputName="'fields_' + key"
									   :ref="'fields.' + key"
									   :input-label="field.label + ':'"
									   :input-value="this.item.fields[key]"
									   :input-options="(!this.isEmpty(field.params.items)) ? field.params.items : null"
									   input-multiple="false"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :input-required="field.is_required"
									   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

							<SelectBox v-else-if="field.type == 3"
									   :inputId="'fields_' + key"
									   :inputName="'fields_' + key"
									   :ref="'fields.' + key"
									   :input-label="field.label + ':'"
									   :input-value="this.item.fields[key]"
									   :input-options="(!this.isEmpty(field.params.items)) ? field.params.items : null"
									   input-multiple="true"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   :input-required="field.is_required"
									   @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

							<Switcher v-else-if="field.type == 4"
									  :inputId="'fields_' + key"
									  :inputName="'fields_' + key"
									  :ref="'fields.' + key"
									  :input-label="field.label"
									  :input-value="(!this.isEmpty(this.item.fields[key])) ? this.item.fields[key] : null"
									  inputClass="px-0"
									  inputWrapClass="flex-column mb-3"
									  :input-required="field.is_required"
									  @setValue="(value) => this.setAndValidate('fields.' + key, parseInt(value))" />

							<TextArea v-else-if="field.type == 5"
									  :inputId="'fields_' + key"
									  :inputName="'fields_' + key"
									  :ref="'fields.' + key"
									  :input-label="field.label + ':'"
									  :input-value="this.item.fields[key]"
									  inputLabelClass="text-font-secondary mb-3"
									  inputClass="rounded-3 p-2"
									  inputWrapClass="mb-20"
									  :input-required="field.is_required"
									  @setValue="(value) => this.setAndValidate('fields.' + key, value)" />

						</div>
					</div>

					<SelectBox inputId="employee_id"
							   inputName="employee_id"
							   ref="employee_id"
							   inputLabel="Ответственный:"
							   :inputOptions="this.employees_list"
							   :inputValue="this.item.employee_id"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   inputSearch="true"
							   @setValue="(value) => this.setAndValidate('employee_id', value)" />

					<SelectBox inputId="source_id"
							   inputName="source_id"
							   ref="source_id"
							   inputLabel="Источник:"
							   :inputOptions="this.sources_list"
							   :inputValue="this.item.source_id"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   inputSearch="true"
							   @setValue="(value) => this.setAndValidate('source_id', value)" />

					<SelectBox inputId="partner_id"
							   inputName="partner_id"
							   ref="partner_id"
							   inputLabel="Партнер:"
							   :inputOptions="this.partners_list"
							   :inputValue="this.item.partner_id"
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   inputSearch="true"
							   @setValue="(value) => this.setAndValidate('partner_id', value)" />
                </div>

                <div class="modal-body p-20 d-flex flex-column p-1" v-if="requestEditFormStep === 2 && !this.$props.isObjectExist">
                    <ul class="flex-column p-0 mb-20">
                        <li
                            v-for="(param, i) of paramList"
                            :key="'param_' + i"
                            class="border-top border-brd-primary py-3"
                            :class="i === paramList.length - 1 ? 'border-bottom' : null"
                        >
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column me-auto">
                                    <span class="mb-10">{{ param.title }}</span>

                                    <span class="text-font-secondary">{{ param.data }}</span>
                                </div>

                                <div class="d-flex ">
                                    <button
                                        type="button"
                                        class="crm-btn btn btn-outline bg-light-gray rounded-circle me-10"
                                        aria-label="Редактировать"
                                    >
                                        <img :src="require('@/assets/icons/pen-icon.svg')" alt="Редактировать" width="24" height="24" class="d-block">
                                    </button>

                                    <button
                                        type="button"
                                        class="crm-btn btn btn-outline bg-light-gray rounded-circle me-10"
                                        aria-label="удалить"
                                    >
                                        <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24" class="d-block">
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="d-flex align-items-center mb-20">
                        <img :src="require('@/assets/icons/info-icon.svg')" alt="Этап воронки" width="24" height="24" class="me-2">
                        <span class="text-font-light fs-5">Обратите внимание, что отредактированные, но не сохраненные параметры не сохраняются на кнопку «Сохранить»</span>
                    </div>

                    <button 
                        type="button" 
                        class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3" 
                    >
                        Сохранить
                    </button>
                </div>

                <div class="modal-body p-20 d-flex flex-column p-1" v-else-if="requestEditFormStep === 2 && this.$props.isObjectExist">
                    <ObjectCardList 
                        :cardType="'type_1'"
                        :identifier="'requestEdit'"
                    />

                    <button 
                        type="button" 
                        class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3" 
                    >
                        Сохранить
                    </button>
                </div>

				<div class="modal-footer p-20">
					<button type="button" class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3">
						Сохранить
					</button>
				</div>
            </div>
        </div>
    </div>

	<AddEditContractModal @contractAdded="(data) => { if (!this.isEmpty(data.contract)) this.item.contracts.push(data.contract) }" />

</template>

<script>
    import ObjectCardList from "@/components/common/ObjectCardList"
    import InputText from '@/components/inputs/InputText'
    import InputSelect from '@/components/inputs/InputSelect'
    import TextArea from '@/components/inputs/TextArea'
	import api from "@/api";
	import CommonService from "@/services/CommonService";
	import SelectBox from "@/components/inputs/SelectBox";
	import InputNumber from "@/components/inputs/InputNumber";
	import Switcher from "@/components/inputs/Switcher";
	import AddEditContractModal from "@/components/modals/AddEditContractModal";
	import {CommonDataService} from "@/services/CommonDataService";

    export default {
		name: 'EditRequisitionModal',
        components: {
			AddEditContractModal,
			Switcher,
			InputNumber,
			SelectBox,
            ObjectCardList,
            InputText,
            TextArea,
            InputSelect
        },
        props: {
            isObjectExist: { type: Boolean}
        },
        data() {
            return {
				item: {
					id: null,
					client_id: null,
					contracts: [],
					fields: []
				},
				client: null,
				requisition_id: null,
				funnels_list: [],
				types_list: [],
				sources_list: [],
				partners_list: [],
				tags_list: [],
				fields_list: [],
				employees_list: [],
				search_query: '',
				clients_offset: 0,
				clients_list: [],
				clients_proccessed: false,
                isTagListShowed: false,
                requestEditFormStep: 1,
                requestObjectEditFormStep: 1,
				contract_types: [],
                paramList: [
                    {
                        title: 'Тип операции:',
                        data: 'Покупка'
                    },

                    {
                        title: 'Тип объекта:',
                        data: 'Квартиры'
                    },

                    {
                        title: 'Источник:',
                        data: 'Собственники и агенты'
                    },

                    {
                        title: 'Комнат, от:',
                        data: '3'
                    },

                    {
                        title: 'Регион(ы) объекта:',
                        data: 'Московская обл, Москва'
                    },

                    {
                        title: 'Не первый/последний этаж:',
                        data: 'Да'
                    },
                ],
                tagList: [
                    {
                        id: '1',
                        title: 'Банки',
                        isChecked: false
                    },
                    {
                        id: '2',
                        title: 'Дикси',
                        isChecked: false
                    },
                    {
                        id: '3',
                        title: 'Магнит',
                        isChecked: true
                    },
                    {
                        id: '4',
                        title: 'Общепит',
                        isChecked: false
                    },
                    {
                        id: '5',
                        title: 'Новостройки',
                        isChecked: false
                    },
                    {
                        id: '6',
                        title: 'Еще что то',
                        isChecked: false
                    },
                    {
                        id: '7',
                        title: 'Пекарня',
                        isChecked: false
                    },
                ],
				is_form_changed: false,
				errors: [],
            }
        },
        methods: CommonService.mergeRecursive({
			formatPhone(value) {
				return CommonService.formatPhoneNumber(value);
			},
			alertNote(message) {
				return alert(message);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			formatPrice(value, currency, sign) {
				return CommonService.formatPrice(value, currency, sign);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			formatDateTime(datetime, locale, format) {
				return CommonService.formatDateTime(datetime, locale, format);
			},
			getClient(client_id) {
				return api.get('/client', {
					params: {
						client_id: client_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClient()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getClient()::axios', error);

				});
			},
			getRequisition(requisition_id) {
				return api.get('/requisition', {
					params: {
						requisition_id: requisition_id,
					}
				}).then((response) => {

					CommonService.log('debug', 'getRequisition()::axios', {response: response.data});

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisition()::axios', error);

				});
			},
			getFunnels() {
				if (this.$store.clients_funnels)
					return this.$store.clients_funnels;
				else
					return api.get('common/funnels', {
						params: {
							section: 'requisitions',
							use_counters: false,
						}
					})
						.then((response) => {

							CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								let list = [];
								Object.entries(response.data.list).forEach(([key, option]) => {

									let count = '';
									if (option.count)
										count = ' ('+option.count+')';

									list.push({
										value: option.id,
										name: option.name + count
									});
								});

								this.$store.commit('setFunnelsList', {
									section: 'clients',
									list: list
								});

								return list;
							} else {
								return {};
							}

						}).catch(function (error) {

							CommonService.log('error', 'getFunnels()::axios', error);

						});
			},
			getEmployees() {
				return api.get('/common/employees', {
					params: {
						add_groups: true
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						let groups = (response.data.groups) ? response.data.groups : [];
						//console.log(groups);

						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {

								if (employee.group_id) {

									let group_id = employee.group_id;
									if (groups[group_id]) {

										list.push({
											value: false,
											name: '<b>' + groups[group_id] + '</b>'
										});

										delete groups[group_id];
									}
								}

								let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
								list.push({
									value: employee.id,
									name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
								});
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getEmployees()::axios', error);

				});
			},
			getFieldsList() {
				return api.get('/fields', {
					params: {
						section: 'requisitions'
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFieldsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						return response.data.list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getFieldsList()::axios', error);

				});
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								list.push({
									value: source.id.toString(),
									name: source.name
								});
							});
						}

						CommonService.log('info', 'EditRequisitionModal::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSources()::axios', error);

				});
			},
			getTypes(section) {
				return api.get('/common/types', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getTypes()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTypes()::axios', error);

				});
			},
			getTags() {
				return api.get('/common/tags', {
					params: {
						with_managers: true
					}
				}).then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			getPartners() {
				return api.get('/partners/list', {
					params: {
						filters: {
							only_active: 1,
							with_managers: 1
						}
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartners()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							let groups = (response.data.groups) ? response.data.groups : [];
							Object.entries(response.data.list).forEach(([key, partner]) => {
								let group_id = partner.group_id ?? null;
								list.push({
									value: partner.id.toString(),
									name: partner.name,
									company: (!this.isEmpty(groups[group_id])) ? groups[group_id] : null,
								});
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getPartners()::axios', error);

				});
			},
			getContractType(type_id) {

				let contract_type = false;
				if (typeof type_id !== "undefined" && !this.isEmpty(this.contract_types)) {
					this.contract_types.forEach((type) => {
						if (type.value == type_id)
							contract_type = type.name;
					});
				}

				return contract_type;
			},
			async getClientsList(clients_ids) {

				let params = {
					filters: {},
					offset: this.clients_offset ?? 0,
					search_query: this.search_query,
					clients_ids: clients_ids ?? null
				};

				this.clients_proccessed = true;

				CommonService.log('debug', 'getClientsList()', params);

				return api.get('/common/clients', {
					params: {
						params
					}
				}).then((response) => {

					this.clients_proccessed = false;

					CommonService.log('debug', 'getClientsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let clients = [];
						Object.entries(response.data.list).forEach(([key, option]) => {

							let client_name = option.name;

							let contacts = '';
							if (option.phone && option.phone !== '')
								contacts = option.phone;

							if (option.email && contacts !== '')
								contacts = contacts + ', ' + option.email;
							else if (option.email)
								contacts = option.email;

							if (contacts !== '')
								client_name = client_name + ' (' + contacts + ')';

							clients.push({
								value: option.id,
								name: client_name
							});
						});

						return clients;
					}

					return {};

				}).catch(function (error) {

					CommonService.log('error', 'getClientsList()::axios', error);

					this.clients_proccessed = false;
				});
			},
			setAndValidate(name, value) {

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				// Проверки перед установкой значения
				/*if (name) {
					if (typeof value == 'string' && this.inArray(name, [
						'budget',
						'hypothec.object_price',
						'hypothec.first_pay_price',
						'hypothec.co_borrower_income',
						'hypothec.borrower_income',
						'hypothec.borrower_credit_load',
						'credit.sum',
						'credit.load',
						'credit.income'
					])) {
						value = parseInt(value.replace(/[^0-9.-]+/g, ""));
					}
				}*/

				// Валидация значения
				if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

					if (this.$refs[name].inputLabel) {
						let label = this.$refs[name].inputLabel.replace(/:+$/, '');

						let is_skip = false;
						if (name == 'funnel_id' && value == 0)
							is_skip = true;

						if (this.$refs[name].required && !is_skip) {
							if (value) {
								if (value.toString() == '0' || value.toString() == '-1')
									errors.push('Поле `' + label + '` обязательно к заполнению!');
								else if (this.isEmpty(value))
									errors.push('Поле `' + label + '` обязательно к заполнению!');
							} else {
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							}
						}

						if (!this.isEmpty(errors)) {
							this.errors[name] = {
								name: name,
								label: label,
								errors: errors
							};

							this.$refs[name].valid = false;
							this.$refs[name].invalid = errors.join('<br/>');
						} else {
							this.errors[name] = null;
							this.$refs[name].valid = true;
							this.$refs[name].invalid = false;
						}
					}
				}

				// Установка значения
				if (parent && child) {
					if (!this.isEmpty(this.item[parent])) {
						if (typeof (this.item[parent][child]) !== "undefined") {
							this.item[parent][child] = value;
						} else {
							this.item[parent] = {
								...{
									[child]: value
								},
								...this.item[parent]
							};
						}
					} else {
						this.item[parent] = {
							[child]: value
						};
					}
				} else if (parent == null && name) {
					this.item[name] = value;
				} else {
					if (parent && child)
						this.item[parent][child] = null;
					else
						this.item[name] = null;
				}

				this.is_form_changed = true;

				CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });


				if (!this.isEmpty(errors))
					return false;

				return true;
			},
        }, CommonDataService.methods),
		mounted() {
			let modal = document.getElementById('editRequisitionModal');
			if (modal && typeof modal !== "undefined") {

				let _this = this;
				modal.addEventListener('hidden.bs.modal', function (event) {
					_this.item = {
						fields: []
					};
					_this.requisition_id = null;
					_this.client = null;
					_this.search_query = '';
					_this.clients_offset = 0;
					_this.clients_list = [];
					_this.emitter.emit("global.modalClose", modal);
				});

				modal.addEventListener('shown.bs.modal', function (event) {

					_this.item = {
						fields: []
					};
					_this.requisition_id = null;
					_this.client = null;

					let target = event.relatedTarget
					if (typeof target !== "undefined") {

						if (typeof target.getAttribute('data-bs-requisition-id') != "undefined") {
							_this.requisition_id = Number(target.getAttribute('data-bs-requisition-id'));
						} else {
							_this.requisition_id = null;
						}
					}

					if (!_this.isEmpty(_this.requisition_id)) {
						_this.getRequisition(_this.requisition_id).then((requisition) => {
							_this.item = requisition;
							if (!_this.isEmpty(_this.item.client_id)) {
								_this.getClient(_this.item.client_id).then((client) => _this.client = client);
							}
						});
					}

					if (_this.isEmpty(_this.funnels_list))
						_this.getFunnels().then((list) => _this.funnels_list = list);

					if (_this.isEmpty(_this.types_list))
						_this.getTypes('requisitions').then((list) => _this.types_list = list);

					if (_this.isEmpty(_this.sources_list))
						_this.getSources().then((list) => _this.sources_list = list);

					if (_this.isEmpty(_this.partners_list))
						_this.getPartners().then((list) => _this.partners_list = list);

					if (_this.isEmpty(_this.tags_list))
						_this.getTags().then((list) => _this.tags_list = list);

					if (_this.isEmpty(_this.employees_list))
						_this.getEmployees().then(list => _this.employees_list = list);

					if (_this.isEmpty(_this.fields_list))
						_this.getFieldsList().then(list => _this.fields_list = list);

					if (!_this.isEmpty(_this.item.client_id))
						_this.getClientsList([_this.item.client_id]).then(list => { _this.clients_list = [..._this.clients_list, ...list] });
					else
						_this.getClientsList().then(list => { _this.clients_list = [..._this.clients_list, ...list] });

					if (_this.isEmpty(_this.fields_list))
						_this.getTypes('contract_types').then(data => { _this.contract_types = data });

					if (process.env.NODE_ENV == "development") {
						console.debug('editRequisitionModal::show.bs.modal', {
							requisition_id: _this.requisition_id,
						});
					}
					_this.emitter.emit("global.modalShown", modal);
				});
			}
		},
		watch: {
			search_query(value, oldValue) {
				if ((value !== oldValue && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
					this.getClientsList().then(data => {
						this.clients_list = data;
					});
				}
			},
			clients_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					this.getClientsList().then(data => {
						this.clients_list = [...this.clients_list, ...data];
					});
				}
			}
		},
        computed: {
            checkedTags() {
                return this.tagList.filter(tag => tag.isChecked)
            },
        },
    }
</script>