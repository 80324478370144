<template>
	<div class="modal fade" id="closeClientModal" tabindex="-1" aria-labelledby="Закрытие клиента" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">

				<div class="modal-header mb-20 border-0 p-0">
					<span class="modal-title fs-2 fw-semi font-semi">Причина закрытия</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>

				<div class="modal-body d-flex flex-column p-0">

					<InputSelect v-show="!this.is_deal_done"
								 inputId="denial_work"
								 inputLabel="Выберите причину:"
								 inputName="denial_work"
								 ref="denial_work"
								 :inputOptions="this.denial_work_list"
								 :inputValue="this.denial_work"
								 inputClass="rounded-1"
								 inputWrapClass="flex-column mb-3"
								 inputRequired="true"
								 inputUsePlaceholder="true"
								 @setValue="(value) => this.setAndValidate('denial_work', value)"  />

					<TextArea inputId="comment"
							  inputName="comment"
							  ref="comment"
							  inputLabel="Комментарий:"
							  :inputValue="this.comment"
							  inputLabelClass="text-font-secondary mb-3"
							  inputClass="rounded-3 p-2"
							  inputWrapClass="mb-20"
							  :inputRequired="(this.is_deal_done != 1)"
							  @setValue="(value) => this.setAndValidate('comment', value)"  />

					<Switcher inputId="is_deal_done"
							  inputName="is_deal_done"
							  ref="is_deal_done"
							  inputLabel="По клиенту прошла сделка"
							  :inputValue="this.is_deal_done"
							  inputClass="px-0"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.setAndValidate('is_deal_done', value)"  />

					<InputText v-show="this.is_deal_done"
							   inputId="commission"
							   inputName="commission"
							   ref="commission"
							   :inputValue="this.commission"
							   inputLabel="Комиссия:"
							   :inputRequired="this.is_deal_done == 1"
							   inputPlaceholder="Размер комиссии в руб."
							   inputLabelClass="text-font-secondary mb-3"
							   inputClass="rounded-3 p-2"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setAndValidate('commission', value)"  />

				</div>

				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						data-bs-dismiss="modal"
						:disabled="!this.is_valid && !(this.clients_count && this.requisitions_count)"
						@click="this.initClose()">
						Подтвердить
					</button>
				</div>

			</div>
		</div>
	</div>
	<ResultsModal id="closeClient"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"	/>
</template>

<script>

import CommonService from "@/services/CommonService";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import InputSelect from "@/components/inputs/InputSelect";
import TextArea from "@/components/inputs/TextArea";
import Switcher from "@/components/inputs/Switcher";
import InputText from "@/components/inputs/InputText";

export default {
	name: 'CloseClientModal',
	props: {
		clientsIds: { type: Array },
		requisitionsIds: { type: Array },
	},
	data() {
		return {
			denial_work: null,
			denial_work_list: [],
			comment: '',
			is_deal_done: 0,
			commission: null,
			clients_ids: (typeof this.clientsIds !== "undefined") ? this.clientsIds : [],
			requisitions_ids: (typeof this.requisitionsIds !== "undefined") ? this.requisitionsIds : [],
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
			errors: [],
		}
	},
	components: {
		InputText,
		Switcher,
		TextArea,
		InputSelect,
		ResultsModal
	},
	methods: {
		resetData() {
			this.clients_ids = [];
			this.denial_work = null;
			this.comment = '';
			this.is_deal_done = false;
			this.commission = null;
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		addClientIds(client_id) {
			this.clients_ids.push(client_id);
		},
		addRequisitionIds(requisition_id) {
			this.requisitions_ids.push(requisition_id);
		},
		getList(section) {
			if (typeof section !== "undefined") {
				return api.get('/common/list', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getList()::axios', error);

				});
			}
		},
		setAndValidate(name, value) {

			let errors = [];

			/*if (name == 'is_deal_done') {
				setTimeout(this.setAndValidate('comment', this.comment), 600);
			}*/

			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
				if (this.$refs[name].inputLabel) {
					let label = this.$refs[name].inputLabel.replace(/:+$/, '');

					if (this.$refs[name].required) {
						if (value) {
							if (value.toString() == '0' || value.toString() == '-1')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if (!this.isEmpty(errors)) {
						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};
						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.errors[name] = null;
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}
				}
			}

			// Установка значения
			this[name] = value;

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });

			if (!this.isEmpty(errors))
				return false;

			return true;
		},
		initClose() {
			if (this.isEmpty(this.errors)) {
				if (!this.isEmpty(this.requisitions_ids) && this.requisitions_ids) {
					api.post('/requisitions/delete', {
						requisitions_ids: this.requisitions_ids,
						denial: this.denial_work,
						comment: this.comment,
						is_deal: this.is_deal_done,
						commission: this.commission,
					}).then((response) => {
						CommonService.log('debug', 'initClose()::axios', {response});

						if (response.status == 200 && response.data.success) {
							this.resultsModalState.state = 'success';
							this.resultsModalState.title = 'OK';

							if (this.requisitions_count > 1) {
								this.resultsModalState.message = 'Заявки были успешно закрыты!';
							} else {
								this.resultsModalState.message = 'Заявка была успешно закрыта!';
							}

							this.$emit('initClose', {requisitions_ids: this.requisitions_ids});
							this.resetData();
						} else {
							this.resultsModalState.state = 'error';
							this.resultsModalState.title = 'Произошла ошибка!';

							if (this.requisitions_count > 1) {
								this.resultsModalState.message = 'Заявки не были закрыты!';
							} else {
								this.resultsModalState.message = 'Заявка не была закрыта!';
							}
						}
					}).catch(function (error) {

						CommonService.log('error', 'initClose()::axios', error);

						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Произошла ошибка!';
						this.resultsModalState.message = 'Заявка(и) не была(и) закрыт(ы)';

					});
				} else if (!this.isEmpty(this.clients_ids) && this.clients_ids) {
					api.post('/clients/delete', {
						clients_ids: this.clients_ids,
						denial: this.denial_work,
						comment: this.comment,
						is_deal: this.is_deal_done,
						commission: this.commission,
					}).then((response) => {
						CommonService.log('debug', 'initClose()::axios', {response});

						if (response.status == 200 && response.data.success) {
							this.resultsModalState.state = 'success';
							this.resultsModalState.title = 'OK';

							if (this.clients_count > 1) {
								this.resultsModalState.message = 'Клиенты были успешно закрыты!';
							} else {
								this.resultsModalState.message = 'Клиент был успешно закрыт!';
							}

							this.$emit('initClose', {clients_ids: this.clients_ids});
							this.resetData();
						} else {
							this.resultsModalState.state = 'error';
							this.resultsModalState.title = 'Произошла ошибка!';

							if (this.clients_count > 1) {
								this.resultsModalState.message = 'Клиенты не были закрыты!';
							} else {
								this.resultsModalState.message = 'Клиент не был закрыт!';
							}
						}
					}).catch(function (error) {

						CommonService.log('error', 'initClose()::axios', error);

						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Произошла ошибка!';
						this.resultsModalState.message = 'Клиент(ы) не была закрыт(ы)';

					});
				} else {
					this.resultsModalState.state = 'error';
					this.resultsModalState.title = 'Ошибка!';
					this.resultsModalState.message = 'Необходимо выбрать хотя бы одного клинта / одну заявку для закрытия.';
				}
			}
		},
	},
	mounted() {
		this.getList('denial_work').then(data => { this.denial_work_list = data });

		let modal = document.getElementById('closeClientModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.clients_ids = [];
				_this.requisitions_ids = [];
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				_this.clients_ids = [];
				_this.requisitions_ids = [];
				_this.denial_work = null;
				_this.comment = '';
				_this.is_deal_done = 0;
				_this.commission = '';

				let target = event.relatedTarget

				if (typeof target !== "undefined") {

					let client_id = target.getAttribute('data-bs-client-id');
					if (client_id) {
						_this.addClientIds(client_id);
					}

					let requisition_id = target.getAttribute('data-bs-requisition-id');
					if (requisition_id) {
						_this.addRequisitionIds(requisition_id);
					}

					if (client_id) {
						_this.emitter.on('clientLoaded', (item) => {
							let client_id = Number(item.id);
							if (client_id) {
								_this.addClientIds(client_id);
							}
						});
					}

					if (requisition_id) {
						_this.emitter.on('requisitionLoaded', (item) => {
							let requisition_id = Number(item.id);
							if (requisition_id) {
								_this.addRequisitionIds(requisition_id);
							}
						});
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('closeClientModal::show.bs.modal', {
						clients_ids: _this.clients_ids,
						requisitions_ids: _this.requisitions_ids,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		is_valid() {
			return (
				(
					(
						this.is_deal_done != 1 &&
						!this.isEmpty(this.denial_work) &&
						!this.isEmpty(this.comment)
					) ||
					(
						this.is_deal_done == 1 &&
						!this.isEmpty(this.commission)
					)
				) && this.isEmpty(this.errors)
			);
		},
		clients_count() {
			return this.clients_ids.length;
		},
		requisitions_count() {
			return this.requisitions_ids.length;
		},
	}
}
</script>