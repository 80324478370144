<template>
	<div class="modal fade" id="submitToVKModal" ref="submitToVKModal" tabindex="-1" aria-labelledby="Добавление поста в VK" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Опубликовать в VK</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">

					<TextArea inputId="post_text"
							  inputLabel="Текст публикации:"
							  inputName="post_text"
							  inputRows="6"
							  :inputValue="(!this.isEmpty(this.description)) ? this.description : ''"
							  inputClass="rounded-1 mb-10"
							  inputWrapClass="flex-column mb-3"
							  @setValue="(value) => this.description = value" />


					<div v-if="(!this.isEmpty(this.targets_list))">
						<Switcher v-for="target of this.targets_list" :key="target.id"
								  :inputLabel="(target.is_group && !this.isEmpty(target.name)) ? 'Группа «'+target.name+'»' : (target.is_group ? 'На стену группы' : 'На мою стену')"
								  :inputId="'group_target_'+target.id"
								  :ref="'group_target_'+target.id"
								  inputName="targets"
								  :inputValue="(this.targets.includes(target.id)) ? 1 : 0"
								  inputClass="vkontakte px-0"
								  inputLabelClass="text-nowrap-mask pe-2 me-2"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.addRemoveTarget(target.id, value)" />
					</div>

					<div class="d-flex flex-fill mt-1 mb-3">
						<img :src="require('@/assets/icons/info.svg')" alt="Информация" class="me-2" style="margin-top: 2px;" width="16" height="16" />
						<span class="text-dark-gray">
							При наличии фотографий объекта, в пост будут добавлены первые 5 фотографий.
						</span>
					</div>

				</div>
				<div class="modal-footer border-0 p-0">
					<button type="button"
							class="btn btn-primary btn-vkontakte col-12 rounded-3 py-3 text-white fs-3"
							data-bs-dismiss="modal"
							:disabled="this.isEmpty(this.targets)"
							@click="this.submitPost">
						Добавить
					</button>
				</div>
			</div>
		</div>
	</div>
	<ResultsModal
		id="postToVK"
		:state="this.resultsModalState.state"
		:title="this.resultsModalState.title"
		:message="this.resultsModalState.message"
	/>
</template>

<script>
import TextArea from "@/components/inputs/TextArea";
import ToggleButtons from "@/components/inputs/ToggleButtons";
import ResultsModal from "@/components/modals/ResultsModal";
import Switcher from "@/components/inputs/Switcher";
import api from "@/api";
import CommonService from "@/services/CommonService";
export default {
	name: "SubmitToVKModal",
	components: {
		Switcher,
		ResultsModal,
		TextArea
	},
	data() {
		return {
			object_id: null,
			description: '',
			targets: [],
			targets_list: [],
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		addRemoveTarget(target_id, state) {
			if (state) {
				this.targets.push(target_id);
			} else {
				let index = this.targets.indexOf(target_id);
				if (index !== -1) {
					this.targets.splice(index, 1)
				}
			}

			if (this.targets.length == 0) {
				let target = this.targets_list.filter(target => target.id).pop();
				if (typeof target !== "undefined") {
					this.targets.push(target.id);
					this.$refs['group_target_'+target.id].value = 1;
					this.$refs['group_target_'+target.id].checked = true;
				}
			}

			CommonService.log('debug', 'addRemoveTarget()::axios', {state, targets: this.targets});

		},
		getVKGroups() {
			return api.get('/user/vk-groups')
			.then((response) => {

				CommonService.log('debug', 'getVKGroups()::axios', {response: response.data});

				if (response.status == 200 && response.data.success)
					return response.data.list;

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getVKGroups()::axios', error);

			});
		},
		getObjectDescription(object_id) {
			return api.get('/object/description', {
				params: {
					section: 'vk',
					object_id: object_id,
					html: true
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjectDescription()::axios', {response: response.data});

				if (response.status == 200 && response.data.success)
					return response.data.description;

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getObjectDescription()::axios', error);

			});
		},
		submitPost() {

			if (!this.isEmpty(this.description)) {

				api.post('/common/crosspost', {
					object_id: this.object_id,
					section: 'vkontakte',
					targets: this.targets,
					message: this.description,
				}).then((response) => {

					if (process.env.NODE_ENV == "development")
						console.debug('submitPost()::axios', {
							response: response.data
						});

					if (response.status == 200 && response.data.success) {
						this.resultsModalState.state = 'success';
						this.resultsModalState.title = 'Пост в ВК успешно добавлен!';
						this.resultsModalState.message = 'Вы сможете увидеть его перейдя во ВКонтакте.';
					} else {
						this.resultsModalState.state = 'error';
						this.resultsModalState.title = 'Ошибка!';
						this.resultsModalState.message = 'Пост в ВК не был опубликован.';
					}

				}).catch(function (error) {

					CommonService.log('error', 'submitPost()::axios', error);

				});
			}




		}
	},
	mounted() {

		let modal = document.getElementById('submitToVKModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.description = '';
				_this.targets = [];
				_this.targets_list = [];
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('shown.bs.modal', function (event) {

				_this.object_id = null;
				_this.description = '';
				_this.targets = [];
				_this.targets_list = [];

				let target = event.relatedTarget
				if (typeof target !== "undefined") {

					if (typeof target.getAttribute('data-bs-object-id') != "undefined") {
						_this.object_id = Number(target.getAttribute('data-bs-object-id'));
						_this.getObjectDescription(_this.object_id).then((description) => _this.description = description);
						_this.getVKGroups().then((groups) => _this.targets_list = groups);
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('submitToVKModal::show.bs.modal', {
						object_id: _this.object_id,
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	watch: {
		/*'description': function(val, oldVal) {
			this.$nextTick(() => {
				this.description = val;
			});
		}*/
	},
	computed: {

	},
}
</script>