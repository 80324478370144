<template>
	<div :class="wrapperClass">
		<div class="d-inline-flex align-items-stretch input-group" :class="groupClass">
			<div v-if="prepend" v-html="prepend" class="input-group-text text-dark-gray border-0 bg-transparent p-0 pe-3" />
			<div class="d-block w-100">
				<div class="row row-cols-2">
					<div v-if="label" class="col-12 d-flex mb-3">
						<label :for="id" class="form-label d-inine-flex my-auto text-dark-gray" :class="this.labelClass">
							{{ this.label }}<sup v-if="this.required" class="text-danger">*</sup>
						</label>
					</div>
					<div class="col mb-2">
						<input :id="id"
							   type="number"
							   class="form-control" :class="this.class"
							   ref="input_from"
							   :name="name + '_from'"
							   :value="valueFrom"
							   :min="min"
							   :max="max"
							   :step="step"
							   :placeholder="placeholder_from"
							   :disabled="disabled"
							   :readonly="readonly"
							   @change="(event) => this.emitValue(event.target.value, this.$refs.input_to.value)" />
					</div>
					<div class="col mb-2">
						<input :id="id"
							   type="number"
							   class="form-control" :class="this.class"
							   ref="input_to"
							   :name="name + '_to'"
							   :value="valueTo"
							   :min="min"
							   :max="max"
							   :step="step"
							   :placeholder="placeholder_to"
							   :disabled="disabled"
							   :readonly="readonly"
							   @change="(event) => this.emitValue(this.$refs.input_from.value, event.target.value)" />
					</div>
				</div>
			</div>
			<div v-if="append" v-html="append" class="input-group-text text-dark-gray border-0 bg-transparent bg-0 p-0 ps-3" />
			<div v-if="valid && typeof valid !== 'boolean'" class="valid-feedback" v-text="valid" />
			<div v-else-if="invalid && typeof invalid !== 'boolean'" class="invalid-feedback" v-text="invalid" />
		</div>
		<div v-if="help" class="form-text" :class="helpClass" v-html="help" />
	</div>
</template>


<script>
import CommonService from "@/services/CommonService";

export default {
	name: 'InputNumberBetween',
	props: {
		inputId: {type: String},
		inputLabel: {type: String},
		inputLabelClass: {type: String},
		inputName: {type: String},
		inputValueFrom: {type: [Number, String]},
		inputValueTo: {type: [Number, String]},
		inputMin: {type: [Number, String]},
		inputMax: {type: [Number, String]},
		inputStep: {type: [Number, String]},
		inputClass: {type: String},
		inputGroupClass: {type: String},
		inputWrapClass: {type: String},
		inputHelpText: {type: String},
		inputHelpClass: {type: String},
		inputFromPlaceholder: {type: String},
		inputToPlaceholder: {type: String},
		inputDisabled: {type: [String, Boolean], default: false},
		inputRequired: {type: [String, Boolean], default: false},
		inputReadonly: {type: [String, Boolean], default: false},
		inputPrepend: {type: String},
		inputAppend: {type: String},
		inputValid: {type: [String, Boolean], default: false},
		inputInValid: {type: [String, Boolean], default: false},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			//label: (typeof this.inputLabel !== "undefined") ? this.inputLabel : '',
			labelClass: (typeof this.inputLabelClass !== "undefined") ? this.inputLabelClass : 'text-dark-gray',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			//value: (typeof this.inputValue !== "undefined") ? this.inputValue : 0,
			class: (typeof this.inputClass !== "undefined") ? this.inputClass : '',
			groupClass: (typeof this.inputGroupClass !== "undefined") ? this.inputGroupClass : '',
			wrapperClass: (typeof this.inputWrapClass !== "undefined") ? this.inputWrapClass : '',
			help: (typeof this.inputHelpText !== "undefined") ? this.inputHelpText : '',
			helpClass: (typeof this.inputHelpClass !== "undefined") ? this.inputHelpClass : '',
			placeholder_from: (typeof this.inputFromPlaceholder !== "undefined") ? this.inputFromPlaceholder : '',
			placeholder_to: (typeof this.inputToPlaceholder !== "undefined") ? this.inputToPlaceholder : '',
			//disabled: (typeof this.inputDisabled !== "undefined" && this.inputDisabled === 'true'),
			//required: (typeof this.inputRequired !== "undefined" && this.inputRequired === 'true'),
			//readonly: (typeof this.inputReadonly !== "undefined" && this.inputReadonly === 'true'),
			//prepend: (typeof this.inputPrepend !== "undefined") ? this.inputPrepend : false,
			//append: (typeof this.inputAppend !== "undefined") ? this.inputAppend : false,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
		};
	},
	watch: {
		'valueFrom': function(val, oldVal) {
			this.validateInputFrom(val);
		},
		'valueTo': function(val, oldVal) {
			this.validateInputTo(val);
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		emitValue(from, to) {
			this.$emit('setValue', {
				from: (!this.isEmpty(from)) ? Number(from) : null,
				to: (!this.isEmpty(to)) ? Number(to) : null
			});
		},
		validateInputFrom(value) {

			// Если значение не совпадает с шагом инпута проверям остаток от деления и приводим к правильному значению
			if (!this.isEmpty(value) && !this.isEmpty(this.$props.inputStep)) {

				if (!isNaN(Number(value)))
					value = Number(value);

				if (!isNaN(Number(this.$props.inputStep))) {
					let to = this.valueTo;
					value = CommonService.roundTo(value, Number(this.$props.inputStep));
					this.valueFrom = value;
					this.emitValue(value, to);
				}
			}

			let input_from = this.$refs.input_from;
			if (this.invalid) {
				input_from.setCustomValidity(this.invalid);
			} else {
				input_from.setCustomValidity("");
			}
		},
		validateInputTo(value) {

			let input_to = this.$refs.input_to;

			// Если значение не совпадает с шагом инпута проверям остаток от деления и приводим к правильному значению
			if (!this.isEmpty(value) && !this.isEmpty(this.$props.inputStep)) {

				if (!isNaN(Number(value)))
					value = Number(value);

				if (!isNaN(Number(this.$props.inputStep))) {
					let from = this.valueFrom;
					value = CommonService.roundTo(value, Number(this.$props.inputStep));
					this.valueTo = value;
					this.emitValue(from, value);
				}
			}

			if (this.invalid) {
				input_to.setCustomValidity(this.invalid);
			} else {
				input_to.setCustomValidity("");
			}
		},
		validateInput() {
			this.validateInputFrom();
			this.validateInputTo();
		}
	},
	mounted() {
		this.validateInput();
	},
	computed: {
		/*valueFrom() {
			return parseInt(this.$props.inputValueFrom)
		},
		valueTo() {
			return parseInt(this.$props.inputValueTo)
		},*/
		valueFrom: {
			get() {
				return (typeof this.$props.inputValueFrom !== "undefined") ? this.$props.inputValueFrom : '';
			},
			set(value) {
				value = parseFloat(value);
			}
		},
		valueTo: {
			get() {
				return (typeof this.$props.inputValueTo !== "undefined") ? this.$props.inputValueTo : '';
			},
			set(value) {
				value = parseFloat(value);
			}
		},
		disabled() {
			return this.$props.inputDisabled
		},
		required() {
			return this.$props.inputRequired
		},
		readonly() {
			return this.$props.inputReadonly
		},
		min() {
			return parseInt(this.$props.inputMin)
		},
		max() {
			return parseInt(this.$props.inputMax)
		},
		step() {
			return parseInt(this.$props.inputStep)
		},
		label() {
			return (typeof this.inputLabel !== "undefined") ? this.inputLabel : ''
		},
		prepend() {
			return (typeof this.inputPrepend !== "undefined") ? this.inputPrepend : false
		},
		append() {
			return (typeof this.inputAppend !== "undefined") ? this.inputAppend : false
		},
	},
}
</script>