<template>
	<div class="modal fade" id="delegateClientModal" tabindex="-1" aria-labelledby="Выбор нового ответственного пользователя" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div v-if="!this.isEmpty(this.clients_ids)" class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span v-if="this.clients_count > 1" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Назначить отвественного для выбранных клиентов
					</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Назначить отвественного для выбранного клиента
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-else-if="!this.isEmpty(this.requisitions_ids)" class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span v-if="this.requisitions_count > 1" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Назначить отвественного для выбранных заявок
					</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
						Назначить отвественного для выбранной заявки
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<SelectBox inputLabelClass="text-font-secondary mb-3"
							   inputId="new_who_work"
							   inputName="new_who_work"
							   inputLabel="Сотрудник:"
							   :inputOptions="this.employees_list"
							   :inputValue="this.employee_id"
							   inputClass="rounded-1 p-2"
							   inputPlaceholder="Выберите пользователя"
							   inputSearch="true"
							   inputWrapClass="mb-20"
							   @setValue="(value) => this.setEmployee(value)" />
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						:class="{'disabled' : !(this.employee_id || this.employees_list)}"
						data-bs-dismiss="modal"
						@click="this.initTransfer()">
						Передать
					</button>
				</div>
			</div>
		</div>
	</div>
	<ResultsModal id="transferClient"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"	/>
</template>

<script>

import CommonService from "@/services/CommonService";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import SelectBox from "@/components/inputs/SelectBox";

export default {
	name: 'DelegateClientModal',
	props: {
		clientsIds: { type: Array },
		requisitionsIds: { type: Array },
		employeesList: { type: Array }
	},
	data() {
		return {
			employees_list: (typeof this.employeesList !== "undefined") ? this.employeesList : [],
			employee_id: null,
			resultsModalState: {
				state: '',
				title: '',
				message: ''
			},
		}
	},
	components: {
		SelectBox,
		ResultsModal
	},
	methods: {
		resetData() {
			this.employees_list = [];
			this.employee_id = null;
			this.resultsModalState = {
				state: '',
				title: '',
				message: ''
			};
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		setEmployee(value) {

			CommonService.log('debug', 'setEmployee()', {employee_id: value});

			this.employee_id = value;
		},
		getEmployees() {

			let _this = this;
			return api.get('/common/employees', {
				params: {
					self_exclude: true,
					add_groups: false
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, employee]) => {
							list.push({
								value: employee.id.toString(),
								name: (employee.department !== 'null') ? employee.name : employee.name + ' (' + employee.department + ')'
							});
						});
					}

					if (typeof _this.$store !== "undefined")
						_this.$store.commit('setEmployeesListExcluded', list);

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getEmployees()::axios', error);

			});
		},
		initTransfer() {

			let _this = this;
			if ((!_this.isEmpty(_this.clients_ids) && _this.clients_count > 0) || !_this.isEmpty(_this.requisitions_ids) && _this.requisitions_count > 0) {

				let request = {};
				if (!_this.isEmpty(_this.clients_ids)) {
					request = api.put('/clients/transfer', {
						clients_ids: _this.clients_ids,
						employee_id: _this.employee_id
					});
				} else if (!_this.isEmpty(_this.requisitions_ids)) {
					request = api.put('/requisitions/transfer', {
						requisitions_ids: _this.requisitions_ids,
						employee_id: _this.employee_id
					});
				}

				request.then((response) => {

					CommonService.log('debug', 'initTransfer()::axios', {response});

					if (response.status == 200 && response.data.success) {
						_this.resultsModalState.state = 'success';
						_this.resultsModalState.title = 'OK';

						if (!_this.isEmpty(_this.clients_ids)) {
							if (_this.clients_count > 1)
								_this.resultsModalState.message = 'Клиенты были успешно переданы другому сотруднику!';
							else
								_this.resultsModalState.message = 'Клиент был успешно передан другому сотруднику!';
						} else if (!_this.isEmpty(_this.requisitions_ids)) {
							if (_this.requisitions_count > 1)
								_this.resultsModalState.message = 'Заявки были успешно переданы другому сотруднику!';
							else
								_this.resultsModalState.message = 'Заявка была успешно передана другому сотруднику!';
						}

						_this.$emit('initTransfer', {clients_ids: _this.clients_ids, employee_id: _this.employee_id});
						_this.resetData();
					} else {
						_this.resultsModalState.state = 'error';
						_this.resultsModalState.title = 'Произошла ошибка!';

						if (!_this.isEmpty(_this.clients_ids)) {
							if (_this.clients_count > 1)
								_this.resultsModalState.message = 'Клиенты не были переданы другому сотруднику.';
							else
								_this.resultsModalState.message = 'Клиент не был передан другому сотруднику.';
						} else if (!_this.isEmpty(_this.requisitions_ids)) {
							if (_this.requisitions_count > 1)
								_this.resultsModalState.message = 'Заявки не были переданы другому сотруднику.';
							else
								_this.resultsModalState.message = 'Заявка не была передана другому сотруднику.';
						}
					}
				}).catch(function (error) {

					CommonService.log('error', 'initTransfer()::axios', error);

					_this.resultsModalState.state = 'error';
					_this.resultsModalState.title = 'Произошла ошибка!';

					if (!_this.isEmpty(_this.clients_ids)) {
						if (_this.clients_count > 1)
							_this.resultsModalState.message = 'Клиенты не были переданы.';
						else
							_this.resultsModalState.message = 'Клиент не был передан.';
					} else if (!_this.isEmpty(_this.requisitions_ids)) {
						if (_this.requisitions_count > 1)
							_this.resultsModalState.message = 'Заявки не были переданы.';
						else
							_this.resultsModalState.message = 'Заявка не была передана.';
					}
				});
			} else {
				_this.resultsModalState.state = 'error';
				_this.resultsModalState.title = 'Ошибка!';

				if (_this.isEmpty(_this.clients_ids) && _this.isEmpty(_this.requisitions_ids))
					_this.resultsModalState.message = 'Необходимо выбрать клиента/заявку для передачи.';
				else if ((!_this.isEmpty(_this.clients_ids) || !_this.isEmpty(_this.requisitions_ids)) && _this.isEmpty(_this.employee_id))
					_this.resultsModalState.message = 'Необходимо выбрать сотрудника для передачи.';

			}
		},
	},
	mounted() {

		if (!this.isEmpty(this.$store.getters.employeesListExcluded))
			this.employees_list = this.$store.getters.employeesListExcluded;
		else
			this.getEmployees().then(data => { this.employees_list = data });

	},
	computed: {
		clients_ids() {
			return this.$props.clientsIds ?? []
		},
		clients_count() {
			return this.clients_ids.length
		},
		requisitions_ids() {
			return this.$props.requisitionsIds ?? []
		},
		requisitions_count() {
			return this.requisitions_ids.length
		},
	}
}
</script>