<template>
    <div>
        <div class="dropdown dropstart">
            <button class="d-flex crm-action-dropdown align-items-center crm-btn-xs justify-content-center rounded-3"
					:class="{
						'btn btn-secondary bg-white border border-brd-primary': this.disabled,
						'btn btn-success icon-color-white': !this.disabled,
					}"
					type="button"
					id="actionsListDropdown"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					:disabled="this.disabled">
                <img :src="require('@/assets/icons/menu-black-icon.svg')"
					 class="d-block"
					 alt="Развернуть список"
					 width="14"
					 height="14" />
            </button>
            <div class="dropdown-menu shadow p-0 m-0 rounded-1 border-0 me-3"
				 aria-labelledby="actionsListDropdown"
				 style="box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);">
                <img :src="require('@/assets/icons/dropdown-side-arr-icon.svg')"
					 alt="Меню действий"
					 width="18"
					 height="14"
					 aria-hidden="true"
					 style="position: absolute; top: 13px; right: -9px; z-index: -1">
                <ul v-if="!this.isEmpty(this.list)" class="m-0 p-0">
                    <li v-for="(item, index) in this.list"
						:key="index"
						class="border-bottom border-brd-primary">
                        <a role="button"
						   class="dropdown-item d-flex align-items-center py-3 px-10"
						   :href="!this.isEmpty(item.href) ? item.href : 'javascript:{}'"
						   :data-bs-toggle="!this.isEmpty(item.modal) ? 'modal' : false"
						   :data-bs-target="item.modal"
						   :title="item.title">
                            <img v-if="!this.isEmpty(item.icon)"
								 :src="item.icon"
								 :alt="item.title"
								 width="16"
								 height="16"
								 class="me-10" />
                            <span class="fs-4 text-font-secondary" v-text="item.label" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
	import CommonService from "@/services/CommonService";

    export default {
        name: 'ActionsListDropdown',
		props: {
			actionsList: { type: Array },
			isDisabled: { type: Boolean }
		},
        components: {

        },
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
		},
		computed: {
			list() {
				return this.$props.actionsList ?? [];
			},
			disabled() {
				return this.$props.isDisabled ?? false;
			}
		}
    }
</script>